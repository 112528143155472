import api from '../../../api/config';

export const getProductInfo = (payload) => api.get(`/products?id=${payload.productId}`);
//working
// export const getProductInfo = async (payload) => {
//     try {
//         const [productResponse, companyResponse] = await Promise.all([
//             api.get(`/products?id=${payload.productId}`),
//             api.get(`/companies?id=${payload.companyId}`)
//         ]);

//         console.log("API Responses:", productResponse, companyResponse);

//         // Ensure responses contain valid data
//         const product = productResponse?.data.data[0]; 
//         const company = companyResponse?.data?.companies[0]; 

//         if (!product || !company) {
//             throw new Error("Invalid API response: Product or company data is missing");
//         }

//         // Append company details to product
//         product.companyName = company.title;
//         product.stripeId = company.stripeId;

//         return productResponse; // Returning modified productResponse

//     } catch (error) {
//         console.error("Error fetching product or company details:", error);
//         throw error;
//     }
// };
