import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { kebabCase, isEmpty } from 'lodash';

import ROUTES from 'routes';
import { UPDATE_USER_PROFILE } from 'modules/ProfilePage/components/Tabs/ProfileTab/actions';
import { SET_LOGIN_MODAL_SHOWED } from 'commonComponents/layouts/CommonLayout/actions';
import {
  GET_COMPANY,
  SELECT_COMPANY_CATEGORY,
  GET_FILTERED_COMPANY_PRODUCTS,
  UPDATE_COMPANY_FILTERED_PRODUCTS,
} from '../actions';
import CompanyPage from '../components/CompanyPage';

const CompanyPageContainer = () => {
  const dispatch = useDispatch();
  const { companyPath, categoryName = '' } = useParams();
  const history = useHistory();

  const { _id, follows } = useSelector((state) => state.user);
  const { filterByPeopleCategory } = useSelector((state) => state.global);
  const {
    companyInfo,
    filteredProducts,
    productsByCategories,
    isLoading,
    selectedCategory,
    pageCount,
    errors,
  } = useSelector((state) => state.company);

  useEffect(() => {
    return () => {
      dispatch(SELECT_COMPANY_CATEGORY(''));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(GET_COMPANY(companyPath));
  }, [dispatch, companyPath]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      history.push(`${ROUTES.HOME}`);
    }
  }, [errors, history]);

  const productCategoriesFilteredBySex = useMemo(() => {
    return productsByCategories.filter((category) => category.section === filterByPeopleCategory);
  }, [filterByPeopleCategory, productsByCategories]);

  useEffect(() => {
    if (productsByCategories.length > 0) {
      const selectedCategoryObj = productCategoriesFilteredBySex.find(
        (category) => kebabCase(category.label) === categoryName
      );

      selectedCategoryObj
        ? history.push(`${ROUTES.COMPANY}/${companyPath}/${categoryName}`)
        : history.push(`${ROUTES.COMPANY}/${companyPath}`);

      selectedCategoryObj
        ? dispatch(SELECT_COMPANY_CATEGORY(selectedCategoryObj._id))
        : dispatch(SELECT_COMPANY_CATEGORY(''));
    }
  }, [
    dispatch,
    categoryName,
    productCategoriesFilteredBySex,
    history,
    companyPath,
    filterByPeopleCategory,
    productsByCategories.length,
  ]);

  const handleClickCard = useCallback(
    (productName, productId,companyId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  const handleCategoryClick = useCallback(
    (category) => {
      history.push(`${ROUTES.COMPANY}/${companyPath}/${category}`);
    },
    [history, companyPath]
  );

  const handleGetFilteredProducts = useCallback(
    (pageNumber, query) => {
      let modifiedQuery = `page=${pageNumber}&${query}&category=`;

      if (selectedCategory !== '') {
        modifiedQuery += `${selectedCategory}`;
      } else {
        productCategoriesFilteredBySex.forEach((category) => {
          modifiedQuery += `${category._id},`;
        });

        modifiedQuery = modifiedQuery.slice(0, -1);
      }

      dispatch(
        GET_FILTERED_COMPANY_PRODUCTS({ companyId: companyInfo.shopifyId, query: modifiedQuery })
      );
    },
    [dispatch, companyInfo.shopifyId, productCategoriesFilteredBySex, selectedCategory]
  );

  const handleUpdateFilteredProducts = useCallback(
    (products) => {
      dispatch(UPDATE_COMPANY_FILTERED_PRODUCTS(products));
    },
    [dispatch]
  );

  const handleFollowCompany = useCallback(
    (selectedCompanyId) => {
      if (_id) {
        const newFollows = follows.slice();
        const companyIndex = newFollows.indexOf(selectedCompanyId);

        if (companyIndex > -1) {
          newFollows.splice(companyIndex, 1);
          dispatch(UPDATE_USER_PROFILE({ userId: _id, follows: newFollows }));
        } else {
          newFollows.unshift(selectedCompanyId);
          dispatch(UPDATE_USER_PROFILE({ userId: _id, follows: newFollows }));
        }
      } else {
        dispatch(SET_LOGIN_MODAL_SHOWED(true));
      }
    },
    [follows, _id, dispatch]
  );

  const isFollow = useMemo(() => follows.includes(companyInfo._id), [follows, companyInfo]);

  return (
    <CompanyPage
      products={filteredProducts}
      companyInfo={companyInfo}
      isLoading={isLoading}
      handleClickCard={handleClickCard}
      productsByCategories={productCategoriesFilteredBySex}
      handleCategoryClick={handleCategoryClick}
      selectedCategory={selectedCategory}
      handleFollowCompany={handleFollowCompany}
      handleGetFilteredProducts={handleGetFilteredProducts}
      handleUpdateFilteredProducts={handleUpdateFilteredProducts}
      isFollow={isFollow}
      pageCount={pageCount}
    />
  );
};

export default React.memo(CompanyPageContainer);
