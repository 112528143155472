import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { kebabCase } from 'lodash';

import CommonLayoutContainer from 'commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import { changeImageSize } from 'helpers/changeImageSize';
import CommonCarousel from 'commonComponents/CommonCarousel';
import ProductCard from 'commonComponents/ProductCard/components/ProductCard';
import FiltersLayoutContainer from 'commonComponents/layouts/FiltersLayout/containers/FiltersLayoutContainer.js';

import styles from './styles.js';

const CompanyPage = ({
  classes,
  handleClickCard,
  productsByCategories,
  products,
  companyInfo,
  isLoading,
  selectedCategory,
  handleCategoryClick,
  handleFollowCompany,
  handleGetFilteredProducts,
  handleUpdateFilteredProducts,
  isFollow,
  pageCount,
}) => {
  const selectedCategoryObj =
    selectedCategory !== '' &&
    productsByCategories.find((category) => category._id === selectedCategory);
console.log("productsByCategories on company page",productsByCategories);

  return (
    <CommonLayoutContainer isLoading={isLoading}>
      <FiltersLayoutContainer
        withBanner
        companyInfo={companyInfo}
        selectedCategory={selectedCategory}
        productsByCategories={productsByCategories}
        handleFollowCompany={handleFollowCompany}
        handleGetFilteredProducts={handleGetFilteredProducts}
        handleUpdateFilteredProducts={handleUpdateFilteredProducts}
        isFollow={isFollow}
        pageCount={pageCount}
      >
        {(isEmptySubmittedQuery) => (
          <>
            {!selectedCategory &&
              products.length === 0 &&
              isEmptySubmittedQuery &&
              productsByCategories.map((category) => (
                <CommonCarousel
                  key={category._id}
                  title={category.label}
                  withViewAllButton
                  viewAllClickHandler={() => handleCategoryClick(kebabCase(category.label))}
                >
                  {category.products.map((product) => (
                    <ProductCard
                      key={product._id}
                      style={classes.item}
                      image={product.image && changeImageSize(product.image.src, 300, 300)}
                      product={product}
                      handleClick={() => handleClickCard(kebabCase(product.title), product._id,companyInfo._id)}
                    />
                  ))}
                </CommonCarousel>
              ))}
            <Grid spacing={4} container>
              {selectedCategoryObj && (
                <Grid xs={12} item>
                  <Typography className={classes.title} variant="h3">
                    {selectedCategoryObj.label}
                  </Typography>
                </Grid>
              )}
              {products.map((product) => (
                <Grid key={product._id} xs={6} sm={6} md={4} lg={3} item>
                  <ProductCard
                    style={classes.item}
                    image={product.image && changeImageSize(product.image.src, 300, 300)}
                    product={product}
                    handleClick={() => handleClickCard(kebabCase(product.title), product._id,companyInfo._id)}
                  />
                </Grid>
              ))}
              {(!isEmptySubmittedQuery || selectedCategory) && products.length === 0 && (
                <Grid xs={12} item>
                  <Typography className={classes.notFound} variant="body1" color="textPrimary">
                    No items have been found
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </FiltersLayoutContainer>
    </CommonLayoutContainer>
  );
};

CompanyPage.defaultProps = {
  products: [],
  companyInfo: {},
  productsByCategories: [],
  isLoading: false,
  isFollow: false,
  selectedCategory: '',
};

CompanyPage.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  productsByCategories: PropTypes.oneOfType([PropTypes.array]),
  companyInfo: PropTypes.oneOfType([PropTypes.object]),
  products: PropTypes.oneOfType([PropTypes.array]),
  handleClickCard: PropTypes.func.isRequired,
  handleCategoryClick: PropTypes.func.isRequired,
  handleFollowCompany: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  handleGetFilteredProducts: PropTypes.func.isRequired,
  handleUpdateFilteredProducts: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isFollow: PropTypes.bool,
};

export default React.memo(withStyles(styles)(CompanyPage));
