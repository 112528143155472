import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { changeImageSize } from 'helpers/changeImageSize';
import ProductPopup from '../components/ProductPopup';
import { ADD_TO_CART } from '../../../modules/CartPage/actions';

const ProductPopupContainer = ({ productInfo, handleClick, cardPosition, showPopup }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    colorRadio: '',
    sizeRadio: '',
  });

  const getOptionKey = useCallback(
    (optionName) => {
      const colorOption =
        productInfo.options && productInfo.options.find((option) => option.name === optionName);
      const optionKey = colorOption && colorOption.position;

      return `option${optionKey}`;
    },
    [productInfo]
  );

  const getProductOptions = useCallback(
    (optionName) => {
      return productInfo.options.find((option) => option.name === optionName);
    },
    [productInfo.options]
  );

  const availableProductSizes = useMemo(
    () =>
      productInfo.variants &&
      productInfo.variants
        .filter(
          (variant) =>
            variant[getOptionKey('Color')] === formData.colorRadio && variant.inventoryQuantity > 0
        )
        .map((variant) => variant[getOptionKey('Size')]),
    [productInfo, getOptionKey, formData.colorRadio]
  );

  const defaultColor = useMemo(
    () => getProductOptions('Color') && getProductOptions('Color').values[0],
    [getProductOptions]
  );

  const defaultSize = useMemo(
    () =>
      productInfo.variants &&
      productInfo.variants.find((variant) => variant[getOptionKey('Color')] === defaultColor)[
        getOptionKey('Size')
      ],
    [productInfo, getOptionKey, defaultColor]
  );

  useEffect(() => {
    if (defaultColor && defaultSize) {
      setFormData({ sizeRadio: defaultSize, colorRadio: defaultColor });
    }
  }, [defaultColor, defaultSize]);

  const onChangeFields = useCallback(
    (event) => {
      if (event.target.name === 'colorRadio') {
        const availableVariant = productInfo.variants.find(
          (variant) =>
            variant[getOptionKey('Color')] === event.target.value && variant.inventoryQuantity > 0
        );

        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
          sizeRadio: availableVariant ? availableVariant[getOptionKey('Size')] : formData.sizeRadio,
        });
      } else {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      }
    },
    [formData, setFormData, getOptionKey, productInfo.variants]
  );

  const currentProductVariant = useMemo(
    () =>
      productInfo.variants &&
      productInfo.variants.find(
        (variant) =>
          variant[getOptionKey('Color')] === formData.colorRadio &&
          variant[getOptionKey('Size')] === formData.sizeRadio
      ),
    [productInfo.variants, getOptionKey, formData]
  );

  const currentProductImage = useMemo(() => {
    const variantImage =
      currentProductVariant &&
      productInfo.images.find((image) => image.id == currentProductVariant.imageId);

    const productDefaultImage = productInfo && productInfo.image && productInfo.image.src;

    return variantImage
      ? changeImageSize(variantImage.src, 300, 300)
      : changeImageSize(productDefaultImage, 300, 300);
  }, [currentProductVariant, productInfo]);

  const handleAddToCart = useCallback(() => {
    const { _id, vendor, title, storeId, companyName, companyStripeId,stripeId } = productInfo;
    const { id, marketPrice, mortarPrice } = currentProductVariant;

    const itemToCart = {
      productId: _id,
      variantId: id,
      vendor: companyName,
      companyStripeId:companyStripeId||stripeId,
      producer: vendor,
      title,
      image: currentProductImage,
      color: currentProductVariant[getOptionKey('Color')],
      size: currentProductVariant[getOptionKey('Size')],
      price: mortarPrice || marketPrice,
      quantity: 1,
      storeId,
    };
    console.log("product card is",productInfo);
    dispatch(ADD_TO_CART(itemToCart));
  }, [productInfo, dispatch, getOptionKey, currentProductVariant, currentProductImage]);

  return (
    <ProductPopup
      formData={formData}
      productInfo={productInfo}
      onChange={onChangeFields}
      availableProductSizes={availableProductSizes}
      currentProductVariant={currentProductVariant}
      cardPosition={cardPosition}
      handleClick={handleClick}
      showPopup={showPopup}
      handleAddToCart={handleAddToCart}
      currentProductImage={currentProductImage}
      getProductOptions={getProductOptions}
    />
  );
};

ProductPopupContainer.defaultProps = {
  productInfo: {},
  showPopup: false,
};

ProductPopupContainer.propTypes = {
  productInfo: PropTypes.oneOfType([PropTypes.object]),
  cardPosition: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleClick: PropTypes.func.isRequired,
  showPopup: PropTypes.bool,
};

export default React.memo(ProductPopupContainer);
