import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, kebabCase } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { Grid, Typography, withStyles } from '@material-ui/core';

import CommonLayoutContainer from 'commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import { changeImageSize } from 'helpers/changeImageSize';
import CommonCarousel from 'commonComponents/CommonCarousel';
import ProductCard from 'commonComponents/ProductCard/components/ProductCard';
import FiltersLayoutContainer from 'commonComponents/layouts/FiltersLayout/containers/FiltersLayoutContainer.js';

import styles from './styles.js';

const CategoriesPage = ({
  classes,
  handleClickCard,
  productsByCategories,
  products,
  isLoading,
  selectedCategory,
  handleCategoryClick,
  handleGetFilteredProducts,
  handleUpdateFilteredProducts,
  getNextCategory,
  categories,
  pageCount,
}) => {  
  const sortedProducts = {};
  categories.forEach(category => {
    if (productsByCategories[category._id]) {
      sortedProducts[category._id] = productsByCategories[category._id];
    }
  });
  // Output the sorted object
  const productsByCategoriesKeys = sortedProducts && Object.keys(sortedProducts);
  console.log(sortedProducts);
  
  const selectedCategoryObj =
    selectedCategory !== '' && categories.find((category) => category._id === selectedCategory);

  return (
    <CommonLayoutContainer isLoading={isLoading}>
      <FiltersLayoutContainer
        productsByCategories={categories}
        handleGetFilteredProducts={handleGetFilteredProducts}
        handleUpdateFilteredProducts={handleUpdateFilteredProducts}
        selectedCategory={selectedCategory}
        currentPage="Categories"
        pageCount={pageCount}
      >
        {(isEmptySubmittedQuery) => (
          <>
            {!selectedCategory && products.length === 0 && isEmptySubmittedQuery && (
              <InfiniteScroll
                pageStart={0}
                loadMore={getNextCategory}
                hasMore={categories.length > productsByCategoriesKeys.length}
                threshold={130}
                initialLoad={productsByCategoriesKeys.length < 7}
              >
                <Grid container spacing={4}>
                  {productsByCategoriesKeys.length > 0 &&
                    productsByCategoriesKeys.map((categoryId) => {
                      const categoryProducts = productsByCategories[categoryId];

                      const categoryObj = categories.find(
                        (category) => category._id === categoryId
                      );
                      return (
                        !isEmpty(categoryProducts) && (
                          <React.Fragment key={categoryId}>
                            <Grid item xs={12}>
                              <CommonCarousel
                                title={categoryObj && categoryObj.label}
                                withViewAllButton
                                viewAllClickHandler={() =>
                                  handleCategoryClick(kebabCase(categoryObj && categoryObj.label))
                                }
                              >
                                {categoryProducts.map((product) => (
                                  <ProductCard
                                    key={product._id}
                                    style={classes.item}
                                    image={
                                      product.image && changeImageSize(product.image.src, 300, 300)
                                    }
                                    product={product}
                                    handleClick={() =>
                                      handleClickCard(kebabCase(product.title), product._id)
                                    }
                                  />
                                ))}
                              </CommonCarousel>
                            </Grid>
                          </React.Fragment>
                        )
                      );
                    })}
                </Grid>
              </InfiniteScroll>
            )}
            <Grid spacing={4} container>
              {selectedCategoryObj && (
                <Grid xs={12} item>
                  <Typography className={classes.title} variant="h3">
                    {selectedCategoryObj.label}
                  </Typography>
                </Grid>
              )}
              {products.map((product) => (
                <Grid key={product._id} xs={6} sm={6} md={4} lg={3} item>
                  <ProductCard
                    style={classes.item}
                    image={product.image && changeImageSize(product.image.src, 300, 300)}
                    product={product}
                    handleClick={() => handleClickCard(kebabCase(product.title), product._id)}
                  />
                </Grid>
              ))}
              {(!isEmptySubmittedQuery || selectedCategory) && products.length === 0 && (
                <Grid xs={12} item>
                  <Typography className={classes.notFound} variant="body1" color="textPrimary">
                    No items have been found
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </FiltersLayoutContainer>
    </CommonLayoutContainer>
  );
};

CategoriesPage.defaultProps = {
  products: [],
  categories: [],
  productsByCategories: {},
  isLoading: false,
  selectedCategory: '',
};

CategoriesPage.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageCount: PropTypes.number.isRequired,
  productsByCategories: PropTypes.oneOfType([PropTypes.object]),
  products: PropTypes.oneOfType([PropTypes.array]),
  categories: PropTypes.oneOfType([PropTypes.array]),
  handleClickCard: PropTypes.func.isRequired,
  handleCategoryClick: PropTypes.func.isRequired,
  getNextCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  handleGetFilteredProducts: PropTypes.func.isRequired,
  handleUpdateFilteredProducts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default React.memo(withStyles(styles)(CategoriesPage));
