// export const changeImageSize = (image, width, height) => {
//   if (image) {
//     return image.replace(/.(gif|jpe?g|bmp|png)/, (extension) => `_${width}x${height}${extension}`);
//   }
//   return false;
// };

// export const changeImageSize = (image, width, height) => {
//   if (!image) return false;

//   return image.replace(/(_\d+x\d+)?(\.(gif|jpe?g|bmp|png))(?=\?|$)/i, `_${width}x${height}$2`);
// };

export const changeImageSize = (image, width, height) => {
  if (!image) return false;

  return image.replace(/(.*)(_\d+x\d+)?(\.(gif|jpe?g|bmp|png))(\?.*)?$/, `$1_${width}x${height}$3$5`);
};

