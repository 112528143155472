import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ROUTES from 'routes';
import { UPDATE_PRODUCTS } from 'modules/RecentlyViewedPage/actions';
import { GET_PRODUCT_INFO } from '../actions';

import ProductPage from '../components/ProductPage';
import { changeImageSize } from '../../../helpers/changeImageSize';
import { ADD_TO_CART } from '../../CartPage/actions';

const ProductPageContainer = () => {
  const dispatch = useDispatch();
  const { productPath,companyId } = useParams();

  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState({
    colorRadio: '',
    sizeRadio: '',
  });

  const { productInfo, isLoading, errors } = useSelector((state) => state.product);
  const { products } = useSelector((state) => state.recentlyViewed);

  useEffect(() => {
    const indexOfId = productPath.lastIndexOf('-');
    const productId = productPath.slice(indexOfId + 1, productPath.length);

    dispatch(GET_PRODUCT_INFO({productId,companyId}));
  }, [dispatch, productPath]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      history.push(`${ROUTES.HOME}`);
    }

    if (!isEmpty(productInfo)) {
      productInfo.companyId=companyId
      dispatch(UPDATE_PRODUCTS(productInfo));
    }
  }, [productInfo, dispatch, errors, history]);

  const getOptionKey = useCallback(
    (optionName) => {
      const colorOption =
        productInfo.options && productInfo.options.find((option) => option.name === optionName);
      const optionKey = colorOption && colorOption.position;

      return `option${optionKey}`;
    },
    [productInfo]
  );

  const getProductOptions = useCallback(
    (optionName) => {
      return (
        productInfo.options && productInfo.options.find((option) => option.name === optionName)
      );
    },
    [productInfo.options]
  );

  const availableProductSizes = useMemo(
    () =>
      productInfo.variants &&
      productInfo.variants
        .filter(
          (variant) =>
            variant[getOptionKey('Color')] === formData.colorRadio && variant.inventoryQuantity > 0
        )
        .map((variant) => variant[getOptionKey('Size')]),
    [productInfo, getOptionKey, formData.colorRadio]
  );

  const defaultColor = useMemo(
    () => getProductOptions('Color') && getProductOptions('Color').values[0],
    [getProductOptions]
  );

  const defaultSize = useMemo(
    () =>
      productInfo.variants &&
      productInfo.variants.find((variant) => variant[getOptionKey('Color')] === defaultColor)[
        getOptionKey('Size')
      ],
    [productInfo, getOptionKey, defaultColor]
  );

  useEffect(() => {
    if (defaultColor && defaultSize) {
      setFormData({ sizeRadio: defaultSize, colorRadio: defaultColor });
    }
  }, [defaultColor, defaultSize]);

  const onChangeFields = useCallback(
    (event) => {
      if (event.target.name === 'colorRadio') {
        const availableVariant = productInfo.variants.find(
          (variant) =>
            variant[getOptionKey('Color')] === event.target.value && variant.inventoryQuantity > 0
        );

        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
          sizeRadio: availableVariant ? availableVariant[getOptionKey('Size')] : formData.sizeRadio,
        });
      } else {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      }
    },
    [formData, setFormData, getOptionKey, productInfo.variants]
  );

  const currentProductVariant = useMemo(
    () =>
      productInfo.variants &&
      productInfo.variants.find(
        (variant) =>
          variant[getOptionKey('Color')] === formData.colorRadio &&
          variant[getOptionKey('Size')] === formData.sizeRadio
      ),
    [productInfo.variants, getOptionKey, formData]
  );

  const currentProductImage = useMemo(() => {
    const variantImage =
      currentProductVariant &&
      productInfo.images.find((image) => image.id == currentProductVariant.imageId);

    const productDefaultImage = productInfo && productInfo.image && productInfo.image.src;

    return variantImage
      ? changeImageSize(variantImage.src, 300, 300)
      : changeImageSize(productDefaultImage, 300, 300);
  }, [currentProductVariant, productInfo]);

  const handleChangeSelectedTab = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const handleClickProductCard = useCallback(
    (productName, productId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  const handleAddToCart = useCallback(() => {
    const { _id, vendor, title, storeId, companyName, companyStripeId,stripeId } = productInfo;
    const { id, marketPrice, mortarPrice } = currentProductVariant;

    const itemToCart = {
      productId: _id,
      variantId: id,
      vendor: companyName,
      companyStripeId :companyStripeId||stripeId,
      producer: vendor,
      title,
      image: currentProductImage,
      color: currentProductVariant[getOptionKey('Color')],
      size: currentProductVariant[getOptionKey('Size')],
      price: mortarPrice || marketPrice,
      quantity: 1,
      storeId,
    };

    dispatch(ADD_TO_CART(itemToCart));
  }, [productInfo, dispatch, getOptionKey, currentProductVariant, currentProductImage]);

  return (
    <ProductPage
      formData={formData}
      productInfo={productInfo}
      onChange={onChangeFields}
      selectedTab={selectedTab}
      handleChangeSelectedTab={handleChangeSelectedTab}
      isLoading={isLoading}
      availableProductSizes={availableProductSizes}
      currentProductVariant={currentProductVariant}
      recentlyViewedProducts={products.slice(0, 10)}
      handleClickProductCard={handleClickProductCard}
      handleAddToCart={handleAddToCart}
    />
  );
};

export default React.memo(ProductPageContainer);
