import React from 'react';
import { v1 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Box, withStyles, useTheme, useMediaQuery, Typography } from '@material-ui/core';
import { kebabCase } from 'lodash';

import CommonLayoutContainer from 'commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import { getFirstCompanyImage } from 'helpers/mediaHelpers.js';
import CommonCarousel from 'commonComponents/CommonCarousel';
import CompanyCard from 'commonComponents/CompanyCard';
import palette from 'theme/palette';
import CommonButton from 'commonComponents/CommonButton';
import ProductCard from 'commonComponents/ProductCard/components/ProductCard';
import BannersPanel from 'commonComponents/BannersPanel';
import { useRouteToGo } from 'helpers/hooks/useRouteToGo';
import ROUTES from 'routes';
import { PEOPLE_CATEGORIES } from 'constants/peopleCategories';

import styles from './styles.js';

const HomePage = ({
  classes,
  products,
  handleClickProductCard,
  handleClickCompanyCard,
  isLoading,
  companies,
  handleFollowCompany,
  follows,
  filterByPeopleCategory,
  handlePeopleFilterChange,
  topCompaniesArray,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const goToCategories = useRouteToGo(ROUTES.CATEGORIES);
  const goToBrandsList = useRouteToGo(ROUTES.BRANDS_LIST);
  const goToRecentlyViewedPage = useRouteToGo(ROUTES.RECENTLY_VIEWED_PAGE);
  const goToFavorites = useRouteToGo(ROUTES.FAVORITES);
const compayData= topCompaniesArray.filter((company) => company.companyInfo).map((company) => company.companyInfo)

  return (
    <CommonLayoutContainer isLoading={isLoading}>
      {isMobileView ? (
        <Box className={classes.filters}>
          <CommonButton
            label="Women"
            variant="text"
            className={classes.filterButton}
            style={{ color: filterByPeopleCategory === PEOPLE_CATEGORIES.WOMEN && palette.black }}
            onClick={() => handlePeopleFilterChange(PEOPLE_CATEGORIES.WOMEN)}
          />
          <CommonButton
            label="Men"
            variant="text"
            className={classes.filterButton}
            style={{ color: filterByPeopleCategory === PEOPLE_CATEGORIES.MEN && palette.black }}
            onClick={() => handlePeopleFilterChange(PEOPLE_CATEGORIES.MEN)}
          />
        </Box>
      ) : (
        <>
         <Box className={classes.greeting}>
            <Typography  color="primary"  variant="body1" sx={{ textAlign: "center", mt: 2}}>
              Discover new brands and find amazing deals all in one place!
            </Typography>
          </Box>
          <Box className={classes.filters}>
            <CommonButton
              label="Brands"
              variant="text"
              className={classes.filterButton}
              onClick={goToBrandsList}
            />
            <CommonButton
              label="Categories"
              variant="text"
              className={classes.filterButton}
              onClick={goToCategories}
            />
            <CommonButton
              label="Favorites"
              variant="text"
              className={classes.filterButton}
              onClick={goToFavorites}
            />
          </Box>
         
        </>
      )}
      <BannersPanel
        withAdditionalBanners
        // companies={topCompaniesArray.map((company) => company.companyInfo)}
        companies={topCompaniesArray.filter((company) => company.companyInfo).map((company) => company.companyInfo)}
        handleClick={handleClickCompanyCard}
        withShopButton
      />
      <CommonCarousel withViewAllButton viewAllClickHandler={goToBrandsList} companies={companies}>
        {companies.map((company) => (
          <CompanyCard
            key={uuid()}
            style={classes.item}
            company={{
              title: company.title,
              description: company.landingDescription,
              image: getFirstCompanyImage(company.media),
              socialNetworks: company.socialNetworks,
            }}
            handleClick={() => handleClickCompanyCard(company.pathName)}
            handleFollowCompany={() => handleFollowCompany(company._id)}
            isFollow={follows.includes(company._id)}
          />
        ))}
      </CommonCarousel>
      {products.length > 0 && (
        <CommonCarousel
          title="Recently Viewed"
          withViewAllButton
          viewAllClickHandler={goToRecentlyViewedPage}
        >
          {products.map((product) => (
            <ProductCard
              key={product.id}
              style={classes.item}
              image={product.image ? product.image.src : ''}
              product={product}
              handleClick={() => handleClickProductCard(kebabCase(product.title), product._id,product.companyId)}
            />
          ))}
        </CommonCarousel>
      )}
    </CommonLayoutContainer>
  );
};

HomePage.defaultProps = {
  products: [],
  companies: [],
  follows: [],
};

HomePage.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  follows: PropTypes.oneOfType([PropTypes.array]),
  companies: PropTypes.oneOfType([PropTypes.array]),
  topCompaniesArray: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleClickProductCard: PropTypes.func.isRequired,
  handleClickCompanyCard: PropTypes.func.isRequired,
  handleFollowCompany: PropTypes.func.isRequired,
  filterByPeopleCategory: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handlePeopleFilterChange: PropTypes.func.isRequired,
  products: PropTypes.oneOfType([PropTypes.array]),
};

export default React.memo(withStyles(styles)(HomePage));
