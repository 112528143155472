import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { kebabCase, isEqual } from 'lodash';

import api from 'api/config';
import { PEOPLE_CATEGORIES } from 'constants/peopleCategories';
import { validateBaseInput, validateEmail } from 'helpers/validations';
import SnackBar from 'commonComponents/SnackBar';
import Loading from 'commonComponents/Loading';
import CompanyInformationForm from '../components/CompanyInformationForm';
import {
  CHANGE_FORM_FIELD,
  CHANGE_IMAGES,
  CHANGE_VIDEOS,
  GET_COMPANY_INFO,
  SET_TO_REMOVE_LIST,
  UPDATE_MERCHANT_COMPANY,
  CLEAR_COMPANY_TAB_MESSAGE,
  CHANGE_TARGETS,
} from '../actions';
import { socialNetworksMapper } from '../../../../../utils/socialNetworksMapper';

const CompanyTabContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_COMPANY_INFO());
  }, [dispatch]);

  const companyInformationSelector = useSelector((state) => state.merchantTabs.companyTab);
  const contacts = useSelector((state) => state.merchantTabs?.companyTab?.form.contacts);

  const isContactInfoValid = contacts.every(
    (contact) => validateBaseInput(contact.title) && validateEmail(contact.email)
  );

  const { _id } = useSelector((state) => state.user);

  const { form, isSuccess, message, isLoading } = companyInformationSelector;
  const { companyTarget } = form;

  const [targetsFor, setTargets] = useState(companyTarget);

  const isTitleValid = useMemo(() => validateBaseInput(form.title), [form.title]);
  const isPathNameValid = useMemo(() => validateBaseInput(form.pathName), [form.pathName]);

  useEffect(() => {
    setTargets(companyTarget);
  }, [companyTarget]);

  useEffect(() => {
    dispatch(CHANGE_TARGETS(targetsFor));
  }, [targetsFor, dispatch]);

  const handleTargetsSet = useCallback(
    (value) => {
      if (value === PEOPLE_CATEGORIES.ALL) {
        setTargets([PEOPLE_CATEGORIES.MEN, PEOPLE_CATEGORIES.WOMEN]);

        return;
      }
      const targetIndex = targetsFor.findIndex((target) => target === value);

      if (targetIndex > -1) {
        const copy = [...targetsFor];

        copy.splice(targetIndex, 1);
        setTargets(copy);
      } else {
        setTargets([...targetsFor, value]);
      }
    },
    [targetsFor]
  );

  const handleFormFieldsChange = useCallback(
    (event, index = null) => {
      const formValuesCopy = { ...companyInformationSelector.form };

      if (index === null) {
        formValuesCopy[event.target.name] = event.target.value;

        if (event.target.name === 'title') {
          formValuesCopy.pathName = kebabCase(event.target.value);
        }
      } else {
        formValuesCopy.contacts[index][event.target.name] = event.target.value;
      }

      dispatch(CHANGE_FORM_FIELD(formValuesCopy));
    },
    [companyInformationSelector.form, dispatch]
  );

  const handleImagesChange = useCallback(
    (images) => {
      dispatch(CHANGE_IMAGES(images));
    },
    [dispatch]
  );

  const handleImageRemove = useCallback(
    (fileName, event) => {
      event.stopPropagation();

      const uploadedMediaCopy = [...companyInformationSelector.images];
      const fileIndexToRemove = uploadedMediaCopy.findIndex(
        (file) => fileName === file.name || fileName === file.original
      );

      const deletedFile = uploadedMediaCopy.splice(fileIndexToRemove, 1)[0];

      if (deletedFile.id) {
        dispatch(SET_TO_REMOVE_LIST({ id: deletedFile.id, name: deletedFile.name, type: 'image' }));
      }

      dispatch(CHANGE_IMAGES(uploadedMediaCopy));
    },
    [dispatch, companyInformationSelector.images]
  );

  const handleVideosChange = useCallback(
    (videos) => {
      dispatch(CHANGE_VIDEOS(videos));
    },
    [dispatch]
  );

  const handleVideosRemove = useCallback(
    (video) => {
      if (video.id) {
        dispatch(SET_TO_REMOVE_LIST({ id: video.id, name: video.name, type: 'video' }));
      }

      dispatch(CHANGE_VIDEOS([]));
    },
    [dispatch]
  );

  const handleAddContact = useCallback(() => {
    const formValuesCopy = { ...companyInformationSelector.form };

    formValuesCopy.contacts = [
      ...formValuesCopy.contacts,
      { name: '', title: '', email: '', phone: '' },
    ];

    dispatch(CHANGE_FORM_FIELD(formValuesCopy));
  }, [dispatch, companyInformationSelector.form]);

  const handleRemoveContact = useCallback(
    (contactInfo) => {
      const formValuesCopy = { ...companyInformationSelector.form };

      formValuesCopy.contacts = formValuesCopy.contacts.filter(
        (contact) => !isEqual(contact, contactInfo)
      );

      dispatch(CHANGE_FORM_FIELD(formValuesCopy));
    },
    [dispatch, companyInformationSelector.form]
  );

  const handleSubmit = useCallback(() => {
    const { video, images, mediaToRemove } = companyInformationSelector;
    console.log("images",images);
    
    const { instagram, facebook, twitter, pinterest, shipping, returns, customerService } = form;
    const mappedSocialNetworks = socialNetworksMapper({ instagram, facebook, twitter, pinterest });
    const mappedServiceLinks = socialNetworksMapper({ shipping, returns, customerService });

    api.defaults.headers['Content-Type'] = 'multipart/form-data';

    const generalInfo = {
      title: form.title,
      establishedYear: form.year,
      city: form.city,
      contacts: form.contacts,
      pathName: form.pathName,
      description: form.description,
      landingDescription: form.landingDescription,
      socialNetworks: mappedSocialNetworks,
      serviceLinks: mappedServiceLinks,
      companyTarget: form.companyTarget,
    };

    const formData = new FormData();

    formData.append('merchantId', _id);
    formData.append('companyId', form._id);
    formData.append('generalInfo', JSON.stringify(generalInfo));
    formData.append('mediaToRemove', JSON.stringify(mediaToRemove));
    const filteredImages = images.filter(image => !image.id);
    console.log("filteredImages",filteredImages);
    

    [...video, ...filteredImages].forEach((mediaFile, index) => {
      formData.append(`files${index}`, mediaFile.file);
    });

    dispatch(UPDATE_MERCHANT_COMPANY(formData));

    api.defaults.headers['Content-Type'] = 'application/json';
  }, [companyInformationSelector, form, dispatch, _id]);

  const handleRegisterMessageClear = useCallback(() => {
    dispatch(CLEAR_COMPANY_TAB_MESSAGE());
  }, [dispatch]);

  return (
    <>
      <CompanyInformationForm
        companyInformation={companyInformationSelector}
        onFormChange={handleFormFieldsChange}
        onImagesChange={handleImagesChange}
        onImageRemove={handleImageRemove}
        inVideoRemove={handleVideosRemove}
        onVideosChange={handleVideosChange}
        onSubmit={handleSubmit}
        isTitleValid={isTitleValid}
        isPathNameValid={isPathNameValid}
        handleTargetsSet={handleTargetsSet}
        targetsFor={targetsFor}
        onAddContact={handleAddContact}
        onRemoveContact={handleRemoveContact}
        isContactInfoValid={isContactInfoValid}
      />
      <SnackBar
        isSuccess={isSuccess}
        message={message}
        onHandleClose={handleRegisterMessageClear}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

export default React.memo(CompanyTabContainer);
