import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';

import styles from './styles.js';

const Gallery = ({ classes, mediaFiles, handleImageRemove }) => {
  const renderThumbnails = useCallback(
    (imageObject, index) => {
      return (
        <div key={index}>
          <img src={imageObject.original} alt="thumbnail" />
          <button
            type="button"
            className={classes.removeThumbnailButton}
            onClick={(event) => handleImageRemove(imageObject.name || imageObject.original, event)}
          >
            Remove
          </button>
        </div>
      );
    },
    [handleImageRemove, classes]
  );
  console.log("mediaFiles", mediaFiles);

  return (
    <div className={classes.galleryWrapper}>
      <ImageGallery
        items={mediaFiles}
        showPlayButton={false}
        thumbnailPosition="left"
        showIndex
        renderThumbInner={(imageObject, index) => {
          console.log("RenderThumbInner Called:", imageObject, index);
          return renderThumbnails(imageObject, index);
        }}
        additionalClass={classes.customImageGallery}
      />
      {/* {mediaFiles.map((imageObject,index)=>
      <div key={index}>
      <img src={imageObject.original} alt="thumbnail" />
      <button
        type="button"
        className={classes.removeThumbnailButton}
        onClick={(event) => handleImageRemove(imageObject.name || imageObject.original, event)}
      >
        Remove
      </button>
    </div>
)} */}

    </div>
  );
};

Gallery.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mediaFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleImageRemove: PropTypes.func.isRequired,
};
export default React.memo(withStyles(styles)(Gallery));
